import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { func, shape, string } from 'prop-types';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';

import { fetchIncomingInvoiceForEdition } from 'actions/incoming-invoice';
import { EDIT_MODE } from 'constants/common/crud';
import { FROM_DUPLICATE_QUERY_PARAM } from 'constants/incoming-invoices';
import IncomingInvoiceCreator from 'containers/IncomingInvoices/IncomingInvoiceCreator/IncomingInvoiceCreator';
import { getDefaultCategory } from 'components/LineItems/utils';
import LoadingIcon from 'components/LoadingIcon';

const InvoiceEdit = ({
  location = {},
  needsAnotherDatevReport,
  invoiceId,
  customInvoiceId = '',
  fetchIncomingInvoiceForEdition,
  lineCategories,
  invoiceLineItems,
  selectedCategory,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [showDialogFromNewInvoice, setShowDialogFromNewInvoice] = useState(
    needsAnotherDatevReport.showDialog
  );
  const [canSubmit, setCanSubmit] = useState(false);
  const [lineItemsState, setLineItemsState] = useState({});

  const defaultCategory = getDefaultCategory(lineCategories);
  const hasSavedLineItems = !!invoiceLineItems.length;
  const isFromDuplicate = location.search.includes(FROM_DUPLICATE_QUERY_PARAM);
  const bufferLineItemsState = {};
  const handleLineItemStateChange = ({ id, open }) => {
    bufferLineItemsState[id] = open;
    setLineItemsState({ ...lineItemsState, ...bufferLineItemsState, [id]: open });
  };

  const showDatevModal = () => setShowDialogFromNewInvoice(true);
  const closeDatevModal = () => setShowDialogFromNewInvoice(false);

  const fetchIncomingInvoiceForEditionRequest = async () => {
    try {
      setIsFetching(true);
      await fetchIncomingInvoiceForEdition(customInvoiceId || invoiceId);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
    }
  };

  // Handle the saving behaviour of the document based on line items and selected Category
  useEffect(() => {
    //get the keys on lineItemsState
    const keys = Object.values(lineItemsState);
    //check if every line Item is saved/closed
    const allLineItemsSaved = keys.length ? keys.every((open) => !open) : true;

    setCanSubmit(allLineItemsSaved);
  }, [selectedCategory, lineCategories, lineItemsState]);

  useEffect(() => {
    fetchIncomingInvoiceForEditionRequest();
  }, []);

  const { state = {} } = location;
  const { redirectedFromSavedDraftInvoice, redirectedFromDatevModal, isSavedFormPaymentSection } =
    state;
  const { showDialog } = needsAnotherDatevReport;

  const initialRender = useRef(true);
  const eInvoice = state ? state.eInvoice : false;

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      if (redirectedFromSavedDraftInvoice && !showDialog) {
        showDatevModal();
      }
    }
  }, [showDialog, redirectedFromSavedDraftInvoice]);

  return isFetching ? (
    <LoadingIcon />
  ) : (
    <IncomingInvoiceCreator
      isEdit
      isFromDuplicate={isFromDuplicate}
      crudMode={EDIT_MODE}
      showDialogFromNewInvoice={showDialogFromNewInvoice}
      closeDatevModalFromNewInvoice={closeDatevModal}
      redirectedFromSavedDraftInvoice={redirectedFromSavedDraftInvoice}
      redirectedFromDatevModal={redirectedFromDatevModal}
      isSavedFormPaymentSection={isSavedFormPaymentSection}
      hasSavedLineItems={hasSavedLineItems}
      selectedCategory={selectedCategory}
      defaultCategory={defaultCategory}
      lineCategories={lineCategories}
      canSubmit={canSubmit}
      setLineItemState={handleLineItemStateChange}
      eInvoice={eInvoice}
    />
  );
};

InvoiceEdit.propTypes = {
  invoiceId: string.isRequired,
  fetchIncomingInvoiceForEdition: func.isRequired,
  customInvoiceId: string,
  location: shape({}),
  needsAnotherDatevReport: shape,
};

const mapStateToProps = (state, ownProps) => ({
  invoiceId: ownProps.match.params ? ownProps.match.params.id : '',
  needsAnotherDatevReport: state.incomingInvoice.postCreateStatus,
  lineCategories: state.incomingInvoice.lineCategories,
  invoiceLineItems: state.incomingInvoice.lineItems,
});

const mapDispatchToProps = {
  fetchIncomingInvoiceForEdition,
};

const reduxEnhancer = connect(mapStateToProps, mapDispatchToProps);

const enhance = compose(
  reduxEnhancer,
  withRouter,
  reduxForm({
    form: 'invoiceCreator',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    persistentSubmitErrors: true,
  })
);

export default enhance(InvoiceEdit);
