import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import flatten from 'flat';
import { debounce, filter, head, isString, some, values } from 'lodash';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import { fetchAppUploads } from 'actions/app-uploads';
import {
  clearFilters,
  getIncomingInvoices,
  resetIncomingInvoices,
  setPage,
  setQueryParam,
  setStatusFilter,
} from 'actions/incoming-invoices';
import { Resources } from 'constants/resources';
import { InvoiceStatuses } from 'constants/statuses';
import sharedStyles from 'shared/styles/components.module.css';
import { invalidRangeChecker, objectHasValues, t } from 'shared/utils';
import { dateFilterMapper } from 'shared/utils/filters/dateFilterMapper';
import CardView, { HeadingSection, Section } from 'components/CardView';
import { StatusFilter, StatusFilterGroup } from 'components/Filter';
import { FiltersGroup } from 'components/Filter/FiltersGroup/FiltersGroup';
import I18n from 'components/I18n';
import { Pagination } from 'components/Pagination/Pagination';
import { Search, SearchDateGroup, SearchGroup } from 'components/Search';
import CSVDownloadButton from 'components/Table/CSVDownloadButton/CSVDownloadButton';

import IncomingInvoicesTable from '../IncomingInvoicesTable';
import NewEInvoiceButton from '../NewEInvoiceButton/NewEInvoiceButton';
import NewInvoiceButton from '../NewInvoiceButton/NewInvoiceButton';

import localStyles from './Table.module.css';

const styles = { ...sharedStyles, ...localStyles };

const DATE_FILTERS = ['invoiceDateFrom', 'invoiceDateTo', 'dueDateFrom', 'dueDateTo'];

const InvoiceFilters = {
  All: InvoiceStatuses.ALL,
  Open: InvoiceStatuses.OPEN,
  Partly_Paid: InvoiceStatuses.PARTLY_PAID,
  Paid: InvoiceStatuses.PAID,
  Overdue: InvoiceStatuses.OVERDUE,
  Draft: InvoiceStatuses.DRAFT,
  New: InvoiceStatuses.NEW,
};

class Table extends Component {
  static defaultProps = {
    filters: {},
    setFilter: (ident) => ident,
  };

  componentDidMount() {
    const { invoices } = this.props;
    /**
     * Fetch incoming invoices if table rest occurs
     * This action can happen only on componentWillUnmount in Table component (line 87)
     */
    const { isReseted } = head(invoices) || {};

    if (isReseted) {
      this.props.setPage(1);
      this.fetch({ page: 1 });
    }
  }

  componentWillUnmount() {
    const { isResetedFromTable, isFetching } = this.props;

    if (!isResetedFromTable && !isFetching) this.props.resetIncomingInvoices();
  }

  _fetch = ({ page = 1 } = {}, sorting = this.props.sorting, overrideValues = {}) => {
    const isIndexClassicView = objectHasValues(this.props.filters);
    const { dateRange } = this.props;
    const correctPage = overrideValues.page || page;

    this.props.fetchAppUploads();
    this.props.getIncomingInvoices({ page: correctPage }, sorting, {
      isIndexClassicView,
      customFilters: dateRange ? { invoice_date: dateRange } : {},
    });
  };

  fetch = debounce(this._fetch, 200);

  hasAnySearchQuery() {
    const { filters } = this.props;
    return some(
      flatten(
        filter(
          filters,
          (_, key) =>
            key !== 'status' && !(isString(filters.invoice_date) && filters.invoice_date !== '')
        )
      )
    );
  }

  clearField = (name) => this.props.setQueryAction(name);

  render() {
    const {
      isEmpty,
      isFetching,
      invoices,
      invoicesCounters,
      pagination,
      sorting,
      filters,
      setFilter,
      setQueryAction,
      hasInvoicesWithoutFiles = false,
      hasMetaFilters = true,
    } = this.props;
    const hasAnyFiltersApplied = some(flatten(filters));
    const hasAnySearchQuery = this.hasAnySearchQuery();
    const isInvalidRange = invalidRangeChecker({
      start: filters.amountHigherThan,
      end: filters.amountLowerThan,
    });
    const isIndexClassicView = objectHasValues(filters);

    return (
      <CardView className={styles.card}>
        {hasMetaFilters && (
          <HeadingSection>
            <I18n t="expenses.subtitle" className={styles.headingText} />
            <div
              className={cx(styles.pullRight, styles.newInvoice, {
                [styles.hiddenSection]: isEmpty && !hasAnyFiltersApplied,
              })}
            >
              <div className={styles.creationButtonsContainer}>
                <NewInvoiceButton
                  dataId="IncomingInvoices:button-new-invoice"
                  piwikTrackingEnabled
                />
                <NewEInvoiceButton
                  dataId="IncomingInvoices:button-new-einvoice"
                  piwikTrackingEnabled
                />
              </div>
            </div>
            <StatusFilterGroup filterSelector={filters.status} selectFilterAction={setFilter}>
              {values(InvoiceFilters).map((status) => (
                <StatusFilter
                  key={status}
                  isDefault={status === InvoiceFilters.All}
                  name={status === InvoiceFilters.All ? '' : status}
                  dataId={`IncomingInvoices:filter-${status}`}
                >
                  <I18n className={styles.filterLabel} t={`tables.filters.incoming.${status}`} />
                  {`(${invoicesCounters[status] || 0})`}
                </StatusFilter>
              ))}
            </StatusFilterGroup>
            <FiltersGroup
              className={styles.filtersGroup}
              isResetButtonDisabled={!hasAnySearchQuery}
              filters={filters}
              onFiltersChange={({ name, value }) => setQueryAction(name, value)}
              onFiltersReset={this.props.clearFilters}
              placeholder={t('tables.filters.filters_group.incoming_invoices.placeholder')}
              dataIds={{
                input: 'IncomingInvoices:input-search',
                resetButton: 'IncomingInvoices:button-filters-reset',
                toggleDropdownButton: 'IncomingInvoices:button-filters-toggle',
              }}
              additionalFilters={
                <SearchGroup
                  setQueryAction={(name) => (value) => {
                    if (DATE_FILTERS.includes(name))
                      return setQueryAction(name, value, dateFilterMapper);
                    setQueryAction(name, value);
                  }}
                  filters={filters}
                  clearFieldAction={this.clearField}
                >
                  <SearchDateGroup
                    label={t('expenses.table.columns.invoice_date_range')}
                    startDateProps={{
                      name: 'invoiceDateFrom',
                      dataId: 'IncomingInvoices:filters-invoice-date-from',
                    }}
                    endDateProps={{
                      name: 'invoiceDateTo',
                      dataId: 'IncomingInvoices:filters-invoice-date-to',
                    }}
                  />
                  <SearchDateGroup
                    label={t('expenses.table.columns.due_date_range')}
                    startDateProps={{
                      name: 'dueDateFrom',
                      dataId: 'IncomingInvoices:filters-due-date-from',
                    }}
                    endDateProps={{
                      name: 'dueDateTo',
                      dataId: 'IncomingInvoices:filters-due-date-to',
                    }}
                  />
                  <Search
                    placeholder={t('expenses.table.columns.amount_higher_than')}
                    name="amountHigherThan"
                    invalid={isInvalidRange}
                    isCurrency
                    isCurrencySignVisible
                    dataId="IncomingInvoices:filters-amount-range-from"
                  />
                  <Search
                    placeholder={t('expenses.table.columns.amount_lower_than')}
                    invalid={isInvalidRange}
                    name="amountLowerThan"
                    isCurrency
                    isCurrencySignVisible
                    dataId="IncomingInvoices:filters-amount-range-to"
                  />
                </SearchGroup>
              }
            >
              <CSVDownloadButton
                filters={filters}
                sorting={sorting}
                endpoint="incoming_invoices_csv"
                disabled={isEmpty}
                className={styles.csvButton}
                dataId="IncomingInvoices:button-CSVExport"
              />
            </FiltersGroup>
          </HeadingSection>
        )}
        <Section className={styles.section}>
          {isEmpty && hasAnyFiltersApplied && !isFetching ? (
            <I18n
              t="tables.empty"
              className={styles.emptyTableMessage}
              data-id="IncomingInvoices:table-empty-info"
            />
          ) : (
            <IncomingInvoicesTable
              data={invoices}
              sorting={sorting}
              isFetching={isFetching}
              refresh={this.fetch}
              isIndexClassicView={isIndexClassicView}
              hasInvoicesWithoutFiles={hasInvoicesWithoutFiles}
              hasFilters
            />
          )}
        </Section>
        <Section
          className={cx(styles.section, styles.pagination, { [styles.hiddenSection]: isEmpty })}
        >
          <Pagination
            {...pagination}
            request={this._fetch}
            isFetching={isFetching}
            resource={Resources.INCOMING_INVOICES}
          />
        </Section>
      </CardView>
    );
  }
}

Table.propTypes = {
  sorting: shape({}).isRequired,
  getIncomingInvoices: func.isRequired,
  filters: shape({
    status: shape({}),
    invoice_date: shape({}),
    draft: bool,
    files_present: bool,
  }),
  hasInvoicesWithoutFiles: bool,
  resetIncomingInvoices: func.isRequired,
  pagination: shape({
    page: number,
    totalPages: number,
  }).isRequired,
  parsedFilters: shape({}),
  invoices: arrayOf(shape({})),
  invoicesCounters: shape({}),
  isEmpty: bool.isRequired,
  isFetching: bool.isRequired,
  setPage: func.isRequired,
  setFilter: func,
  setQueryAction: func.isRequired,
  clearFilters: func.isRequired,
  fetchAppUploads: func.isRequired,
  dateRange: string,
  hasMetaFilters: bool,
  updateInvoiceFileStatuses: func,
  isResetedFromTable: bool.isRequired,
};

export default connect(
  (state) => ({
    invoices: state.incomingInvoices.data,
    filters: state.incomingInvoices.filters,
    parsedFilters: state.incomingInvoices.parsedFilters,
    sorting: state.incomingInvoices.sorting,
    pagination: state.incomingInvoices.pagination,
    isEmpty: state.incomingInvoices.data.length === 0,
    isFetching: state.incomingInvoices.isFetching,
    invoicesCounters: state.incomingInvoices.invoicesCounters,
    isResetedFromTable:
      head(state.incomingInvoices.data) && !head(state.incomingInvoices.data).isReseted,
  }),
  {
    getIncomingInvoices,
    resetIncomingInvoices,
    setPage,
    setFilter: setStatusFilter,
    setQueryAction: setQueryParam,
    clearFilters,
    fetchAppUploads,
  }
)(Table);
