import React from 'react';
import cx from 'classnames';

import dropFileImage from 'images/icon-upload-blue.svg';
import { t } from 'shared/utils';
import Button, { ButtonAppearances } from 'components/Button';

import OcrInfo from './OcrInfo/OcrInfo';

import styles from './EmptyStateBanner.module.css';

type EmptyStateBannerProps = {
  onNewFileButtonClick: () => void;
  ocrEnabled?: boolean;
  isImport?: boolean;
  title?: string;
  subtitle?: string;
  buttonLabel?: string;
  bottomText?: string;
  dataId?: string;
  isEInvoice?: boolean;
};

const EmptyStateBanner = ({
  onNewFileButtonClick,
  ocrEnabled,
  isImport,
  isEInvoice,
  title = t('expenses.upload.dropzone.title'),
  subtitle = t('expenses.upload.dropzone.text'),
  buttonLabel = t('expenses.upload.browse_files'),
  bottomText,
  dataId,
}: EmptyStateBannerProps) => {
  const acceptedFileTypesMessage = isEInvoice
    ? t('expenses.upload.dropzone.accepted_types_e-import')
    : isImport
    ? t('expenses.upload.dropzone.accepted_types_import')
    : t('expenses.upload.dropzone.accepted_types');

  return (
    <div data-id={dataId} className={styles.section}>
      <div>
        <img className={styles.image} src={dropFileImage} alt="" />
        <h4 className={styles.sectionHeader}>{title}</h4>
        {subtitle && <p className={styles.sectionText}>{subtitle}</p>}
        <Button
          appearance={ButtonAppearances.primary}
          className={styles.button}
          label={buttonLabel}
          onClick={onNewFileButtonClick}
        />
        <p className={cx(styles.sectionText, styles.acceptedTypes)}>
          {bottomText || acceptedFileTypesMessage}
        </p>
        {ocrEnabled && <OcrInfo />}
      </div>
    </div>
  );
};

export default EmptyStateBanner;
