import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';

import { EDIT_MODE } from 'constants/common/crud';
import { INCOMING_INVOICE } from 'constants/folder-names';
import { FORM_NAME } from 'constants/incoming-invoices';
import {
  PIWIK_ACTION_EDIT_INCOMING_INVOICE_SAVE,
  PIWIK_ACTION_NEW_INCOMING_INVOICE_CANCEL,
  PIWIK_ACTION_NEW_INCOMING_INVOICE_SAVE,
  PIWIK_CATEGORY_EXPENSES,
} from 'constants/piwik';
import { DEFAULT_STATE as defaultOcrState } from 'reducers/incoming-invoice/ocr';
import paths from 'routes/paths';
import withOverlayLoader from 'shared/hoc/withOverlayLoader';
import withTransitionPrevent from 'shared/hoc/withTransitionPrevent';
import { noop, t } from 'shared/utils';
import isInvoicePaid from 'shared/utils/isInvoicePaid/is-invoice-paid';
import { piwikHelpers } from 'shared/utils/piwik';
import ActionPanel from 'components/ActionPanel';
import ActionButton from 'components/ActionPanel/ActionButton';
import BankActivity from 'components/BankActivity/BankActivity';
import Card from 'components/Card';
import I18n from 'components/I18n';
import {
  IGNORE_OUTSIDE_CLICK_CLASS,
  LineItemsAutoSaveConsumer,
  LineItemsAutoSaveProvider,
} from 'components/LineItems';
import { InfoModal } from 'components/Modal';

import UploadEInvoiceSection from './../EInvoice/UploadInvoiceSection';
import AbortButton from './AbortButton';
import CreateInvoiceSection from './CreateInvoiceSection/CreateInvoiceSection';
import { mapDispatchToProps, mapStateToProps } from './helper';
import InvoiceDetailsSection from './InvoiceDetailsSection';
import InvoicePaymentSection from './InvoicePaymentSection/InvoicePaymentSection';
import InvoiceSumSection from './InvoiceSumSection';
import UploadInvoiceSection from './UploadInvoiceSection';

import styles from './IncomingInvoiceCreator.module.css';

const InvoiceCreator = ({
  newInvoice = false,
  invoiceId = '',
  ocr = defaultOcrState,
  fetchPaymentsBankAccounts,
  isCancelInvoice = false,
  isDraft,
  handleGetOcrResult,
  fetchPayments,
  isFromDuplicate,
  isEditingInvoice,
  exitEditInvoiceMode,
  refreshValidationData,
  datevDates,
  isEdit,
  submitInvoice,
  handleSubmit,
  push,
  closeDatevModal,
  isSavedFormPaymentSection,
  closeDatevModalFromNewInvoice = noop,
  submitting,
  needsAnotherDatevReport,
  isReadonly,
  initialValues,
  invoiceDetails,
  invoiceAmount,
  uploadedFiles,
  paymentsEnabled,
  invoiceHasCreditNote,
  creatorHasCreditNote,
  redirectedFromSavedDraftInvoice = false,
  redirectedFromDatevModal = false,
  crudMode,
  showDialogFromNewInvoice = false,
  amendmentType,
  disabledSaveButton,
  defaultCategory,
  handleSelectCategory,
  selectedCategory,
  category,
  lineCategories,
  setLineItemState,
  canSubmit,
  showCategoryChangeConfirmation,
  hasSavedLineItems,
  eInvoice = false,
}) => {
  const shouldFetchOCR = !newInvoice && !isCancelInvoice && isDraft && !isFromDuplicate;

  useEffect(() => {
    invoiceId && fetchPayments(invoiceId);
    fetchPaymentsBankAccounts();

    if (shouldFetchOCR && !eInvoice) handleGetOcrResult(invoiceId);

    return () => {
      if (isEditingInvoice) {
        exitEditInvoiceMode();
        refreshValidationData(
          datevDates.values.startDate || datevDates.initial.startDate,
          datevDates.values.endDate || datevDates.initial.endDate
        );
      }
    };
  }, [invoiceId, shouldFetchOCR, isEditingInvoice]);

  const handleOnSubmitPiwikActions = () => {
    if (newInvoice) {
      piwikHelpers.trackEvent(PIWIK_CATEGORY_EXPENSES, PIWIK_ACTION_NEW_INCOMING_INVOICE_SAVE);
    }

    if (isEdit) {
      piwikHelpers.trackEvent(PIWIK_CATEGORY_EXPENSES, PIWIK_ACTION_EDIT_INCOMING_INVOICE_SAVE);
    }

    if (isEditingInvoice) {
      exitEditInvoiceMode();
    }
  };

  const handleAbort = () => {
    if (isEditingInvoice) {
      exitEditInvoiceMode();
    }

    if (newInvoice) {
      piwikHelpers.trackEvent(PIWIK_CATEGORY_EXPENSES, PIWIK_ACTION_NEW_INCOMING_INVOICE_CANCEL);
    }
  };

  const saveInvoice = () => {
    handleOnSubmitPiwikActions();
    handleSubmit(submitInvoice(invoiceId))();
  };

  const saveDraftInvoice = async () => {
    handleOnSubmitPiwikActions();
    // real submit
    try {
      await handleSubmit(submitInvoice(invoiceId))();

      push({
        pathname: paths.editIncomingInvoice(invoiceId),
        state: { redirectedFromSavedDraftInvoice: true, isSavedFormPaymentSection: true },
      });
    } catch (error) {}
  };

  const redirectAfterCreation = (redirectedFromDatevModal = false, isCancelled = false) => {
    const urlProps = `?expandCancelledInvoice=${invoiceId}`;

    if (!isSavedFormPaymentSection)
      return push(`${paths.incomingInvoices}${isCancelled ? urlProps : ''}`);

    closeDatevModalFromNewInvoice();
    closeDatevModal();

    return push({
      pathname: paths.editIncomingInvoice(invoiceId),
      state: {
        redirectedFromSavedDraftInvoice: true,
        redirectedFromDatevModal,
      },
    });
  };

  const hasCreditNote = invoiceHasCreditNote || creatorHasCreditNote;
  const shouldShowPayments = paymentsEnabled && invoiceAmount >= 0 && !hasCreditNote;
  const { alreadyCreated, showDialog } = needsAnotherDatevReport;
  const uploadDisabled = isReadonly('invoiceFiles');
  const isUploadSectionPresent =
    (isEdit && !uploadDisabled) || newInvoice || !isEmpty(uploadedFiles) || isCancelInvoice;
  const shouldTriggerOCR = !isCancelInvoice && !isEdit;
  const { draft } = invoiceDetails.details;
  const isBankTransferAssigmentVisible = !newInvoice && !isCancelInvoice && !draft;
  const isCancellationInvoice = amendmentType === 'cancellation';

  if (alreadyCreated && !showDialog) {
    redirectAfterCreation(false, isCancellationInvoice);
  }

  return (
    <>
      <form>
        <LineItemsAutoSaveProvider>
          <div className={cx(styles.section, styles.isHorizontal)}>
            <div className={styles.subsection}>
              {isUploadSectionPresent && (!eInvoice || crudMode === EDIT_MODE) && (
                <div className={cx(styles.upload, styles.subsectionItem)}>
                  <UploadInvoiceSection
                    shouldTriggerOCR={shouldTriggerOCR}
                    isCancelInvoice={isCancelInvoice}
                    readonly={uploadDisabled}
                  />
                </div>
              )}
              {isUploadSectionPresent && eInvoice && crudMode !== EDIT_MODE && (
                <div className={cx(styles.upload, styles.subsectionItem)}>
                  <UploadEInvoiceSection
                    shouldTriggerOCR={shouldTriggerOCR}
                    isCancelInvoice={isCancelInvoice}
                    readonly={uploadDisabled}
                  />
                </div>
              )}
              <div className={cx(styles.creator, styles.subsectionItem)}>
                <CreateInvoiceSection
                  ocr={ocr}
                  isCancelInvoice={isCancelInvoice}
                  isNewInvoice={newInvoice}
                  isReadonly={isReadonly}
                />
              </div>
            </div>
            <LineItemsAutoSaveConsumer>
              {({ resetNewLineItemCreators, resetEditLineItemCreators }) => (
                <InvoiceDetailsSection
                  readonly={isReadonly('lineItems')}
                  status={invoiceDetails.details.status}
                  crudMode={crudMode}
                  resetNewLineItemCreators={resetNewLineItemCreators}
                  resetEditLineItemCreators={resetEditLineItemCreators}
                  handleSelectCategory={handleSelectCategory}
                  defaultCategory={defaultCategory}
                  isDraft={invoiceDetails?.draft}
                  selectedCategory={selectedCategory}
                  category={category}
                  lineCategories={lineCategories}
                  setLineItemState={setLineItemState}
                  showCategoryChangeConfirmation={showCategoryChangeConfirmation}
                  hasSavedLineItems={hasSavedLineItems}
                />
              )}
            </LineItemsAutoSaveConsumer>
            <div
              className={cx(styles.subsection, styles.parallelSubsection, {
                [styles.inversed]: !shouldShowPayments,
              })}
            >
              {shouldShowPayments && (
                <InvoicePaymentSection
                  isInvoiceDraft={draft}
                  handleSubmit={handleSubmit}
                  saveDraftInvoice={saveDraftInvoice}
                  redirectedFromSavedDraftInvoice={redirectedFromSavedDraftInvoice}
                  redirectedFromDatevModal={redirectedFromDatevModal}
                  isDatevModalOpen={showDialogFromNewInvoice || showDialog}
                />
              )}
              <InvoiceSumSection />
            </div>
            {isBankTransferAssigmentVisible && (
              <Card className={styles.card}>
                <BankActivity
                  isDraft={isDraft}
                  readonly={isReadonly('bankActivity')}
                  invoiceDetails={initialValues} // This value needs to be passed or invoice form will break
                  currentInvoiceDetails={invoiceDetails.details}
                  whichFolder={INCOMING_INVOICE}
                  disabled={isInvoicePaid(invoiceDetails.details)}
                />
              </Card>
            )}
          </div>
          <LineItemsAutoSaveConsumer>
            {({ handleSave }) => (
              <ActionPanel
                className={cx(styles.creatorActionsSection, IGNORE_OUTSIDE_CLICK_CLASS)}
                wrapped
              >
                <ActionButton
                  dataId="IncomingInvoices:button-save"
                  onClick={handleSave(saveInvoice)}
                  disabled={!canSubmit || submitting || disabledSaveButton}
                  label={t(
                    `expenses.form.${isCancellationInvoice ? 'cancel_invoice' : 'save_invoice'}`
                  )}
                />
                <AbortButton
                  dataId="IncomingInvoices:cancel-button"
                  onClickAddon={handleAbort}
                  noRedirect={isEditingInvoice}
                />
              </ActionPanel>
            )}
          </LineItemsAutoSaveConsumer>
        </LineItemsAutoSaveProvider>
      </form>
      <InfoModal
        isOpen={showDialogFromNewInvoice || showDialog}
        onClose={() => redirectAfterCreation(true)}
        message={t('modals.needs_another_datev_report.message')}
        header={t('modals.needs_another_datev_report.title')}
      >
        <I18n t="modals.needs_another_datev_report.message" />
      </InfoModal>
    </>
  );
};
const createEnhancedComponent = (eInvoice) => {
  const baseEnhancers = [
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
      form: FORM_NAME,
      enableReinitialize: true,
      persistentSubmitErrors: true, // allow submitting even if there are errors
    }),
  ];

  if (!eInvoice) {
    baseEnhancers.push(withTransitionPrevent());
  }

  return compose(...baseEnhancers)(InvoiceCreator);
};

const EnhancedInvoiceCreator = (props) => {
  const { eInvoice } = props;

  const RForm = useMemo(() => createEnhancedComponent(eInvoice), [eInvoice]);

  return <RForm {...props} />;
};

const InvoiceCreatorWithLoader = withOverlayLoader(EnhancedInvoiceCreator, {
  alwaysOnTop: true,
  enlarged: true,
  misty: true,
  fixedPosition: true,
  text: t('expenses.upload.running_ocr_processing'),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceCreatorWithLoader);
