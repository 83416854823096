import React, { Component, createRef } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import cx from 'classnames';
import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';

import {
  deleteInvoiceFile,
  downloadFile,
  uploadEInvoiceFile,
} from 'actions/incoming-invoice/uploaded-files';
import { showNotification as showNotificationAction } from 'actions/notification';
import { documentTooBig } from 'notifications/incoming-invoices-creator';
import paths from 'routes/paths';
import { incomingInvoiceDetailsSelector } from 'selectors/incomingInvoice';
import { t, urlWithParams } from 'shared/utils';
import generateFilesUploadInfo from 'shared/utils/ocr';
import EmptyStateBanner from 'components/Dropzone/EmptyStateBanner/EmptyStateBanner';
import Loading from 'components/Loading';

import styles from './UploadInvoiceSection.module.css';

class UploadInvoiceSection extends Component {
  state = {
    isFileUploading: false,
  };

  dropzoneRef = createRef();

  get isEmpty() {
    return isEmpty(this.props.uploadedFiles);
  }

  attachUrlWithParams = (invoiceId, accessToken) => (file) => ({
    ...file,
    url: urlWithParams(`${paths.incomingInvoiceFile(invoiceId, file.id)}`, {
      access_token: accessToken,
    }),
  });

  showFilesErrors = (filesInfo = {}) => {
    const { showNotification } = this.props;
    if (filesInfo.isTooBig) showNotification(documentTooBig);
  };

  generateUploadingFilesInfo = (files) => {
    const filesCount = files.length;
    const filesSize = files
      .map((file) => file.size / 1024 ** 2)
      .reduce((sum, size) => sum + size, 0);
    return generateFilesUploadInfo(filesSize, filesCount);
  };

  setUploading = (value) => {
    this.setState({ isFileUploading: value });
  };

  uploadFile = (files = []) => {
    const { invoiceId, uploadFile } = this.props;

    if (!files.length) return;

    const filesInfo = this.generateUploadingFilesInfo(files);
    this.showFilesErrors(filesInfo);
    if (filesInfo.isTooBig) return;

    this.setUploading(true);

    uploadFile(invoiceId, files).then(() => {
      this.setUploading(false);
    });
  };

  openFilePicker = () => {
    this.dropzoneRef.current.open();
  };

  componentDidUpdate() {
    const { invoiceId } = this.props;

    // If the files are uploaded  we handle the redirect
    if (!this.isEmpty) {
      this.handleRedirect(invoiceId);
    }
  }

  handleRedirect = (invoiceId) => {
    const { navigate } = this.props;
    navigate(paths.editIncomingInvoice(invoiceId), {
      redirectedFromSavedDraftInvoice: true,
      redirectedFromDatevModal: false,
      eInvoice: true,
    });
  };

  render() {
    const { readonly } = this.props;
    const { isFileUploading } = this.state;

    return (
      <div className={styles.main}>
        {!readonly && (
          <Dropzone
            accept={['text/xml', 'application/xml', 'application/pdf']}
            multiple={false}
            onDrop={this.uploadFile}
            ref={this.dropzoneRef}
          >
            {({ getRootProps, getInputProps, isDragAccept }) => (
              <>
                {isFileUploading ? (
                  <Loading />
                ) : (
                  <div
                    className={cx(styles.previewContainer, styles.dropzone, {
                      [styles.isActive]: isDragAccept,
                    })}
                    {...omit(getRootProps(), ['onClick'])}
                  >
                    <input {...getInputProps()} data-id="Dropzone:input" />
                    {this.isEmpty ? (
                      <EmptyStateBanner
                        isEInvoice
                        onNewFileButtonClick={this.openFilePicker}
                        title={t('expenses.upload.dropzone.title')}
                        subtitle={t('expenses.upload.dropzone.text')}
                        buttonLabel={t('expenses.upload.browse_files_invoice')}
                      />
                    ) : null}
                  </div>
                )}
              </>
            )}
          </Dropzone>
        )}
      </div>
    );
  }
}

UploadInvoiceSection.propTypes = {
  readonly: PropTypes.bool,
  accessToken: PropTypes.string.isRequired,
  invoiceId: PropTypes.string.isRequired,
  uploadFile: PropTypes.func.isRequired,
  downloadFilePreview: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })).isRequired,
  showNotification: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    accessToken: state.auth.token,
    invoiceId: incomingInvoiceDetailsSelector(state).id,
    uploadedFiles: state.incomingInvoice.uploadedFiles,
  }),
  (dispatch) => ({
    uploadFile: (invoiceId, files) => dispatch(uploadEInvoiceFile(invoiceId, files)),
    downloadFilePreview: (...args) => dispatch(downloadFile(...args)),
    deleteFile: (invoiceId) => (id) => () => dispatch(deleteInvoiceFile(invoiceId, id)),
    showNotification: (notification) => dispatch(showNotificationAction(notification)),
    navigate: (pathname, state) => dispatch(push({ pathname, state })),
  })
)(UploadInvoiceSection);
