import React from 'react';
import { Link } from 'react-router-dom';
import { noop } from 'lodash';
import { bool, string } from 'prop-types';

import { PIWIK_ACTION_NEW_INCOMING_EINVOICE, PIWIK_CATEGORY_EXPENSES } from 'constants/piwik';
import paths from 'routes/paths';
import styles from 'shared/styles/table.module.css';
import { piwikHelpers } from 'shared/utils/piwik';
import I18n from 'components/I18n';

const handleClick = () => {
  piwikHelpers.trackEvent(PIWIK_CATEGORY_EXPENSES, PIWIK_ACTION_NEW_INCOMING_EINVOICE);
};

const NewEInvoiceButton = ({ isPiwikTrackingEnabled = false, dataId = '' }) => (
  <Link
    data-id={dataId}
    to={paths.newIncomingEInvoice}
    onClick={isPiwikTrackingEnabled ? handleClick : noop}
    className={styles.newInvoice}
  >
    <I18n t="expenses.add_incoming_einvoice" />
  </Link>
);

NewEInvoiceButton.propTypes = {
  isPiwikTrackingEnabled: bool,
  dataId: string,
};

export default NewEInvoiceButton;
